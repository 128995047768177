import React, { useEffect, useRef } from 'react';
import './EyeFollowCursor.css';

type EyeFollowCursorProps = {
  eyeBallImage: string;
  eyeOutlineImage: string;
};

export default function EyeFollowCursor(props: EyeFollowCursorProps) {
  const eyeRef = useRef<HTMLImageElement>(null);
  const pupilRef = useRef<HTMLImageElement>(null);
  const eyeOutlineWidth = 75;
  const eyeOutlineHeight = 100;

  useEffect(() => {
    const eye = eyeRef.current;
    const pupil = pupilRef.current;

    if (eye && pupil) {
      const pupilWidth = pupil.getBoundingClientRect().width;
      const pupilHeight = pupil.getBoundingClientRect().height;

      const handleMouseMove = (e: MouseEvent) => {
        const mousePercentX = e.clientX / document.body.clientWidth;
        const mousePercentY = e.clientY / document.body.clientHeight;
        const posX = ((mousePercentX * 2 - 1) * (eyeOutlineWidth - pupilWidth)) / 2;
        const posY = ((mousePercentY * 2 - 0.25) * (eyeOutlineHeight - pupilHeight)) / 2;

        pupil.style.transform = `translate(${posX}px, ${posY}px)`;
      };

      window.addEventListener('mousemove', handleMouseMove);

      return () => {
        window.removeEventListener('mousemove', handleMouseMove);
      };
    } else {
      return undefined;
    }
  }, []);

  return (
    <div className="eye-wrapper">
      <img className="eye-ball" src={props.eyeBallImage} alt="Eye ball" ref={pupilRef} />
      <img className="eye-outline" src={props.eyeOutlineImage} alt="Eye outline" aria-hidden="true" ref={eyeRef} />
    </div>
  );
}
