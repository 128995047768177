import React, { useState } from 'react';
import { Link } from '@mui/material';

export default function SocialButton(props: any) {
  const [image, setImage] = useState(props.idleImage);

  return (
    <Link
      href={props.href}
      target="_blank"
      rel="noopener"
      onMouseEnter={() => setImage(props.hoveredImage)}
      onMouseDown={() => setImage(props.pressedImage)}
      onMouseLeave={() => setImage(props.idleImage)}
      sx={{ ...props.style, backgroundImage: `url(${image})` }}
      className="social-link"
      title={'social-link'}
    />
  );
}
