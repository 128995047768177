import { Box } from '@mui/material';
import React from 'react';

interface BlankSeparatorProps {
  lg?: string;
  md?: string;
  sm?: string;
  xs?: string;
}

export default function BlankSeparator(props: BlankSeparatorProps) {
  const height = {
    lg: props.lg ? props.lg : props.md || props.sm || props.xs || '0',
    md: props.md ? props.md : props.sm || props.xs || '0',
    sm: props.sm ? props.sm : props.xs || '0',
    xs: props.xs ? props.xs : '0',
  };

  return <Box width={'100vw'} height={height} />;
}
