import React from 'react';
import { Box, Grid } from '@mui/material';
import { PlatformProps } from '../platform-component';
import SubtitleComponent from '../subtitle-component/SubtitleComponent';
import PlatformComponent from '../platform-component/PlatformComponent';
import IconSeparator from '../icon-separator/IconSeparator';
import { abstract_separator } from '../../assets/images';
import BlankSeparator from '../black-separator/BlankSeparator';

interface AddToWishlistProps {
  platforms: PlatformProps[];
}

export default function AddToWishlist(props: AddToWishlistProps) {
  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
      <Box width={'128px'} height={'120px'}>
        <IconSeparator iconImage={abstract_separator} />
      </Box>
      <BlankSeparator sm={'64px'} xs={'32px'} />
      <SubtitleComponent subtitle={'Add to Wish List'} />
      <BlankSeparator xs={'64px'} />
      <Grid container justifyContent="center" columnSpacing={0} rowSpacing={8.5}>
        {props.platforms.map((platform, index) => (
          <Grid
            key={index}
            item
            margin={{
              lg: '0 2.604vw',
              md: '0 1.79vw',
              xs: '0 15px',
            }}
          >
            <PlatformComponent
              platformImage={platform.platformImage}
              platformLink={platform.platformLink}
              platformName={platform.platformName}
              platformAltText={platform.platformAltText}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
