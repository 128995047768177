import { Box } from '@mui/material';
import React from 'react';
import HomeHeader from '../../home-header/HomeHeader';
import FooterComponent from '../../footer-component/FooterComponent';
import MainMenu from '../../main-menu/MainMenu';
import AddToWishlist from '../../add-to-wishlist/AddToWishlist';
import { platforms } from '../../../utils/GeneralUtils';
import LogosSection from '../../logos-section/LogosSection';
import TitleAndSubtitle from '../../title-and-subtitle-component/TitleAndSubtitle';
import { q2_2026 } from '../../../assets/images/title-and-subtitles';
import DeadsideSection from '../../deadside-section/DeadsideSection';
import BlankSeparator from '../../black-separator/BlankSeparator';
import ContentRating from '../../content-rating/ContentRating';
import { shadowman_background } from '../../../assets/images/home-page';
import colors from '../../../utils/colors';

export const HomePagePath = '/home';

const homePageStyle = {
  backgroundColor: colors.black,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  overflowX: 'hidden',
};

export default function HomePage() {
  return (
    <Box sx={homePageStyle}>
      <MainMenu />
      <HomeHeader />
      <Box
        sx={{
          background: `url(${shadowman_background}) no-repeat center center`,
          backgroundPosition: 'center top',
          backgroundSize: 'cover',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <BlankSeparator lg={'54px'} md={'40px'} sm={'20px'} xs={'30px'} />
        <TitleAndSubtitle image={q2_2026} alt={'COMING IN Q2 2026'} />
        <BlankSeparator md={'160px'} sm={'120px'} xs={'60px'} />
        <AddToWishlist platforms={platforms} />
        <BlankSeparator md={'160px'} sm={'120px'} xs={'60px'} />
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <LogosSection />
        </Box>
        <span id={'faq'}></span>
        <BlankSeparator md={'160px'} sm={'140px'} xs={'60px'} />
        <DeadsideSection />
        <BlankSeparator lg={'310px'} sm={'120px'} xs={'20px'} />
        <ContentRating />
        <FooterComponent />
      </Box>
    </Box>
  );
}
