import { createTheme } from '@mui/material';

export const muiTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 601,
      md: 901,
      lg: 1201,
      xl: 1921,
    },
  },
});
