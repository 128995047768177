import React from 'react';
import './LogosSection.css';
import { Box } from '@mui/material';
import IconSeparator from '../icon-separator/IconSeparator';
import { skull_separator } from '../../assets/images';
import SubtitleComponent from '../subtitle-component/SubtitleComponent';
import LogosComponent from '../logos-component/LogosComponent';
import { logosFirstRow, logosSecondRow } from '../../utils/GeneralUtils';
import BlankSeparator from '../black-separator/BlankSeparator';

export default function LogosSection() {
  const styles = {
    logosComponentMainContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    },
    iconSeparator: {
      width: '162px',
      height: '114px',
    },
  };

  return (
    <Box sx={styles.logosComponentMainContainer}>
      <Box sx={styles.iconSeparator}>
        <IconSeparator iconImage={skull_separator}></IconSeparator>
      </Box>
      <BlankSeparator sm={'64px'} xs={'32px'} />
      <SubtitleComponent subtitle={'Shadowman® Darque Legacy \n is being summoned by'}></SubtitleComponent>
      <BlankSeparator xs={'64px'} />
      <LogosComponent logosFirstRow={logosFirstRow} logosSecondRow={logosSecondRow} />
    </Box>
  );
}
