import React from 'react';
import { Box, CardMedia, Typography } from '@mui/material';
import { iarc_16 } from '../../assets/images/content-rating';
import fonts from '../../utils/fonts';
import { contentRatingText } from '../../utils/GeneralUtils';
import colors from '../../utils/colors';

const matureImageStyles = {
  width: '65px',
  marginBottom: '56px',
};

const contentRatingContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: {
    lg: '1000px',
    md: 'calc(100vw - 200px)',
    sm: 'calc(100vw - 80px)',
    xs: 'calc(100vw - 48px)',
  },
};

export default function ContentRating() {
  return (
    <Box sx={contentRatingContainerStyles}>
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
        <CardMedia component={'img'} src={iarc_16} sx={matureImageStyles} alt={'IARC 16+ rating icon'} />
        <Typography textAlign={'center'} fontFamily={fonts.lato} color={colors.white} fontSize={'14px'}>
          {contentRatingText}
        </Typography>
      </Box>
    </Box>
  );
}
