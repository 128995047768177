import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import PublicLayout from '../routes/PublicLayout';
import HomePage from './pages/HomePage/HomePage';
import { ThemeProvider } from '@mui/material';
import { muiTheme } from '../utils';

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={muiTheme}>
        <Routes>
          <Route element={<PublicLayout />}>
            <Route path="/" element={<HomePage />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default App;
