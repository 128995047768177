import './SubtitleComponent.css';
import React from 'react';
import { Box, CardMedia, Typography } from '@mui/material';
import { left_subtitle_decoration, right_subtitle_decoration } from '../../assets/images';
import fonts from '../../utils/fonts';
import colors from '../../utils/colors';

interface Subtitle {
  subtitle: string;
  id?: string;
}

export default function SubtitleComponent(props: Subtitle) {
  const styles = {
    subtitleContainer: {
      marginX: '20px',
    },
    subtitleDecoration: {
      width: '240px',
      height: '45px',
      display: {
        xs: 'none',
        sm: 'none',
        md: 'block',
      },
    },
    subtitleTypography: {
      color: colors.white,
      fontFamily: fonts.poisonHope,
      fontSize: {
        xs: '36px',
        md: '40px',
      },
    },
  };
  return (
    <Box display={'flex'} flexDirection={'row'} alignItems={'center'} padding={'0 10px'}>
      <CardMedia component={'img'} src={left_subtitle_decoration} alt={'Subtitle Decoration'} sx={styles.subtitleDecoration} />
      <Box sx={styles.subtitleContainer}>
        {props.subtitle.split('\n').map((line, index) => (
          <Typography
            component={'h2'}
            sx={styles.subtitleTypography}
            key={index}
            dangerouslySetInnerHTML={{ __html: line.replace(/®/g, '<sup>&reg;</sup>') }}
            id={props.id}
          />
        ))}
      </Box>
      <CardMedia component={'img'} src={right_subtitle_decoration} alt={'Subtitle Decoration'} sx={styles.subtitleDecoration} />
    </Box>
  );
}
