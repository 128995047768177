import fonts from '../../utils/fonts';
import colors from '../../utils/colors';

const styles = {
  hamburgerMainMenuText: {
    fontFamily: fonts.poisonHope,
    fontSize: '22px',
    fontWeight: '400',
    lineHeight: '26px',
    transition: 'color 0.5s',
  },
  followUsText: {
    fontFamily: fonts.poisonHope,
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '26px',
    margin: '45px 0 0 185px',
    color: colors.white,
  },
  subscribeContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '291px',
    height: '27x',
    alignItems: 'center',
    transition: 'all ease 0.5s',
  },
  socialLogo: {
    width: '20px',
    height: '20px',
    transition: 'all ease 0.5s',
  },
  closeButton: {
    height: '50px',
    width: '50px',
    float: 'right',
    marginTop: '20px',
    marginRight: '25px',
    transition: 'all ease 0.5s',
    cursor: 'pointer',
  },
};

export default styles;
