import './MainMenu.css';
import React, { useEffect, useState } from 'react';
import { AppBar, Box, CardMedia, Drawer, Link, Toolbar, Typography } from '@mui/material';
import {
  arrow_icon_white,
  twitter_icon_white,
  valiant_logo,
  vertical_divider_white,
  youtube_icon_white,
  hamburger_menu_icon_white,
  arrow_icon_smoke,
  underline_text_smoke,
  underline_text_white,
  youtube_icon_smoke,
  twitter_icon_smoke,
} from '../../assets/images';
import HamburgerMenu from '../hamburger-menu/HamburgerMenu';
import colors from '../../utils/colors';
import styles from './MainMenuStyle';
import SocialButton from '../social-button/SocialButton';
import SubscriberForm from './SubscriberForm';
import { brandLogoLinks } from '../../utils/GeneralUtils';

export default function MainMenu() {
  const [titleArrowUnderline, setTitleArrowUnderline] = useState<[string, string, string]>([colors.white, arrow_icon_white, '']);

  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);
  const [isPastPoint, setIsPastPoint] = useState(false);
  const toggleHamburgerMenu = () => () => {
    setHamburgerMenuOpen(!hamburgerMenuOpen);
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const pointY = 990;

      if (scrollY > pointY) {
        setIsPastPoint(true);
      } else {
        setIsPastPoint(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleTitleEnter = () => {
    setTitleArrowUnderline([colors.smoke, arrow_icon_smoke, underline_text_smoke]);
  };
  const handleTitleLeave = () => {
    setTitleArrowUnderline([colors.white, arrow_icon_white, '']);
  };
  const handleTitleClicked = () => {
    setTitleArrowUnderline([colors.white, arrow_icon_white, underline_text_white]);
  };

  return (
    <AppBar component="nav" sx={{ ...styles.appBarContainer, backgroundColor: isPastPoint ? 'rgba(0, 0, 0, 0.8)' : 'transparent' }}>
      <Toolbar className="main-menu-toolbar-container">
        <Box sx={styles.valiantLogo}>
          <Link href={brandLogoLinks.valiant} target={'_blank'} rel={'noopener'}>
            <img src={valiant_logo} alt="Valiant logo" />
          </Link>
        </Box>
        <Box sx={styles.mainMenuRightContainer}>
          <Link
            href={'#faq'}
            rel="noopener"
            underline={'none'}
            onMouseEnter={handleTitleEnter}
            onMouseLeave={handleTitleLeave}
            onMouseDown={handleTitleClicked}
            className="main-menu-welcome-container"
            display={'flex'}
            flexDirection={'column'}
          >
            <Box display={'flex'} flexDirection={'row'}>
              <Typography sx={{ ...styles.mainMenuText, color: titleArrowUnderline[0] }} className="main-menu-text title hover">
                WELCOME TO THE DEADSIDE
              </Typography>
              <Box sx={{ ...styles.mainMenuArrow, backgroundImage: `url(${titleArrowUnderline[1]})` }} className="main-menu-arrow" />
            </Box>
            <Box component={'img'} src={titleArrowUnderline[2]} className="main-menu-underline-title" alt="Decorative underline for link" />
          </Link>
          <Box className="main-menu-signs-container">
            <img width={2} height={17} src={vertical_divider_white} alt="Vertical divider" />
            <SocialButton
              style={styles.socialLogo}
              idleImage={youtube_icon_white}
              hoveredImage={youtube_icon_smoke}
              pressedImage={youtube_icon_smoke}
              href={'https://youtu.be/FOmky34JgZ8'}
            ></SocialButton>
            <SocialButton
              style={styles.socialLogo}
              idleImage={twitter_icon_white}
              hoveredImage={twitter_icon_smoke}
              pressedImage={twitter_icon_smoke}
              href={'https://twitter.com/ShadowmanDL'}
            ></SocialButton>
          </Box>
          <Box sx={styles.subscribeContainer}>
            <SubscriberForm />
          </Box>
        </Box>
        {!hamburgerMenuOpen ? (
          <CardMedia
            component="img"
            image={hamburger_menu_icon_white}
            title="Toggle Button"
            sx={styles.hamburgerToggleButton}
            onClick={toggleHamburgerMenu()}
            alt={'Hamburger menu icon'}
          />
        ) : (
          <></>
        )}
      </Toolbar>
      <Drawer
        anchor={'right'}
        open={hamburgerMenuOpen}
        onClose={toggleHamburgerMenu()}
        sx={{ '& .MuiDrawer-paper': styles.hamburgerDrawer, '& .MuiBackdrop-root': styles.hamburgerDrawer }}
      >
        <HamburgerMenu hamburgerMenuButton={hamburgerMenuOpen} setHamburgerMenuButton={setHamburgerMenuOpen} />
      </Drawer>
    </AppBar>
  );
}
