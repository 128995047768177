import fonts from '../../utils/fonts';

const styles = {
  appBarContainer: {
    width: '100%',
    height: '90px',
    boxShadow: 'none',
  },
  valiantLogo: {
    width: '150px',
    height: '40px',
    marginLeft: { sx: 0.5, sm: 0.5, md: 0, lg: 3.5, xl: 3.5 },
  },
  mainMenuRightContainer: {
    display: { xs: 'none', xm: 'none', md: 'flex' },
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '682px',
    height: '26px',
    marginRight: { sx: 0.5, sm: 0.5, md: 0, lg: 3.2, xl: 3.2 },
  },
  subscribeContainer: {
    display: 'flex',
    width: '291px',
    transition: 'all ease 0.5s',
  },
  hamburgerToggleButton: {
    display: { xs: 'flex', md: 'none' },
    width: '50px',
    height: '50px',
    marginTop: '12px',
    transition: 'all ease 0.5s',
    cursor: 'pointer',
  },
  hamburgerDrawer: {
    display: { xs: 'flex', md: 'none' },
    width: 300,
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    boxShadow: 'none',
  },
  mainMenuText: {
    fontFamily: fonts.poisonHope,
    fontSize: '20px',
    fontWeight: '400',
    lineHeight: '24px',
    transition: 'color 0.5s',
  },
  mainMenuArrow: {
    width: '8px',
    height: '12px',
    marginTop: '7px',
    marginLeft: '10px',
    transition: 'all ease 0.5s',
  },
  socialLogo: {
    width: '20px',
    height: '20px',
    transition: 'all ease 0.5s',
  },
};

export default styles;
