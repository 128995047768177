import React from 'react';
import DeadsideComponent from './deadside-component/DeadsideComponent';
import { deadsideParagraphs } from '../../utils/GeneralUtils';
import { Box } from '@mui/material';
import SubtitleComponent from '../subtitle-component/SubtitleComponent';
import EyeFollowCursor from '../eye-follow-cursor-separator/EyeFollowCursor';
import EyeBallImage from '../../assets/svgs/eye-ball.svg';
import EyeOutlineImage from '../../assets/svgs/eye-outline.svg';
import BlankSeparator from '../black-separator/BlankSeparator';

export default function DeadsideSection() {
  return (
    <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
      <Box width={'120px'} height={'120px'}>
        <EyeFollowCursor eyeBallImage={EyeBallImage} eyeOutlineImage={EyeOutlineImage} />
      </Box>
      <BlankSeparator xs={'64px'} />
      <Box>
        <SubtitleComponent subtitle={'WELCOME TO THE DEADSIDE'} />
      </Box>
      <BlankSeparator xs={'48px'} />
      {deadsideParagraphs.map((paragraph, index) => (
        <Box key={index} marginBottom={'35px'}>
          <DeadsideComponent title={paragraph.title} description={paragraph.description} />
        </Box>
      ))}
    </Box>
  );
}
