import React from 'react';
import { shadowman_header_video } from '../../assets/videos';
import { Box, CardMedia, Typography } from '@mui/material';
import { header_bottom_decoration, shadowman_logo } from '../../assets/images/home-header';

const shadowmanLogoStyle = {
  position: 'absolute',
  zIndex: 1,
  top: {
    sm: '0',
    xs: '70.833vw',
  },
  width: {
    md: '791px',
    sm: '87.889vw',
    xs: '86.500vw',
  },
  height: {
    md: '405px',
    sm: '45vw',
    xs: '44.167vw',
  },
};

const headerContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  position: 'relative',
  width: '100%',
  height: {
    sm: '1005px',
    xs: '159.500vw',
  },
};

const headerVideoStyles = {
  display: {
    sm: 'block',
    xs: 'block',
  },
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  zIndex: 0,
};

const headerContentStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
  top: {
    sm: '336px',
    xs: '32.500vw',
  },
  left: '50%',
  transform: 'translateX(-50%)',
};

const headerBottomDecorationStyles = {
  position: 'absolute',
  width: '1988px',
  bottom: '-40px',
  height: '105px',
  zIndex: '0',
};

const hiddenH1Styles = {
  display: 'none',
};

export default function HomeHeader() {
  return (
    <Box sx={headerContainerStyles}>
      <CardMedia component={'video'} src={shadowman_header_video} loop playsInline autoPlay muted sx={headerVideoStyles} />
      <Box sx={headerContentStyles}>
        <Typography component={'h1'} sx={hiddenH1Styles}>
          Shadowman Darque Legacy
        </Typography>
        <CardMedia
          component={'img'}
          src={shadowman_logo}
          sx={shadowmanLogoStyle}
          alt={
            'A Shadowman text logo written as "Shadowman: Darque Legacy. It features a dark, grunge-themed design. The letter M in the logo features a white human figure at its center, surrounded by a red shadow and lighting effect. The overall design is edgy and visually striking.'
          }
        />
      </Box>
      <CardMedia component={'img'} src={header_bottom_decoration} sx={headerBottomDecorationStyles} alt={'Decorative bottom header'} />
    </Box>
  );
}
