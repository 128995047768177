import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Icon, Typography } from '@mui/material';
import { deadside_button_active, deadside_button_normal, deadside_underline } from '../../../assets/images/deadside-section';
import fonts from '../../../utils/fonts';
import colors from '../../../utils/colors';

interface DeadsideComponentProps {
  title: string;
  description: string;
}

const deadsideDescriptionStyles = {
  fontFamily: fonts.lato,
  fontSize: '16px',
  lineHeight: '32px',
  color: colors.white,
  textAlign: 'left',
};

const accordionStyles = {
  backgroundColor: colors.transparent,
  width: {
    lg: '1120px',
    md: 'calc(100vw - 200px)',
    sm: 'calc(100vw - 80px)',
    xs: 'calc(100vw - 48px)',
  },
  '&.MuiAccordion-root.Mui-expanded': {
    width: {
      lg: '1120px',
      md: 'calc(100vw - 200px)',
      sm: 'calc(100vw - 80px)',
      xs: 'calc(100vw - 48px)',
    },
  },
};

const accordionSummaryStyles = {
  padding: '0',
  position: 'relative',
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'none',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'none',
  },
};
const accordionIconStyles = {
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  width: '77px',
  height: '45px',
  transition: 'background-image 0.5s ease-in-out',
};

export default function DeadsideComponent(props: DeadsideComponentProps) {
  const [buttonImage, setButtonImage] = useState(deadside_button_normal);
  const [showText, setShowText] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  const description = props.description.replace(/®/g, '<sup>&reg;</sup>');

  const title = props.title.replace(/®/g, '<sup>&reg;</sup>');

  const deadsideTitleStyles = {
    fontFamily: fonts.poisonHope,
    fontSize: '24px',
    textAlign: 'left',
    color: colors.white,
  };

  const [deadsideUnderlineStyles, setDeadsideUnderlineStyles] = useState({
    width: '0',
    height: '3px',
    transition: 'width 0.5s ease-in-out',
    position: 'absolute',
    bottom: '-9px',
    maxWidth: {
      lg: '1120px',
      md: 'calc(100vw - 200px)',
      sm: 'calc(100vw - 80px)',
      xs: 'calc(100vw - 48px)',
    },
  });

  const mouseEnterHandler = () => {
    if (!showText) {
      setButtonImage(deadside_button_normal);
      setDeadsideUnderlineStyles({ ...deadsideUnderlineStyles, width: '100%' });
    }
  };

  const mouseLeaveHandler = () => {
    if (!showText) {
      setButtonImage(deadside_button_normal);
      setDeadsideUnderlineStyles({ ...deadsideUnderlineStyles, width: '0' });
    } else {
      setButtonImage(deadside_button_active);
    }
    if (buttonClicked) {
      setButtonClicked(false);
    }
  };

  const mouseDownHandler = (event: any) => {
    if (!showText) {
      setButtonImage(deadside_button_active);
    } else {
      setButtonImage(deadside_button_normal);
    }
    if (event.button === 0) {
      setButtonClicked(true);
    }
  };

  const mouseUpHandler = () => {
    if (buttonClicked) {
      setShowText(!showText);
      setButtonClicked(false);
    }
  };

  return (
    <Box>
      <Accordion sx={accordionStyles}>
        <AccordionSummary
          data-testid="faq"
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
          onMouseDown={mouseDownHandler}
          onMouseUp={mouseUpHandler}
          sx={accordionSummaryStyles}
          expandIcon={<Icon title={'icon'} sx={{ ...accordionIconStyles, backgroundImage: `url(${buttonImage})` }} />}
        >
          <Box display={'flex'} flexDirection={'column'} width={'100%'}>
            <Typography sx={deadsideTitleStyles} dangerouslySetInnerHTML={{ __html: title }} className="accordion-heading" />
            <Box component={'img'} src={deadside_underline} sx={deadsideUnderlineStyles} alt={'Deadside decorative underline'} />
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '16px 0' }}>
          <Typography sx={deadsideDescriptionStyles} dangerouslySetInnerHTML={{ __html: description }} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
