import React from 'react';
import './LogosComponent.css';
import { Box, CardMedia, Grid, Link } from '@mui/material';
import { logos_separator } from '../../assets/images';
import IconSeparator from '../icon-separator/IconSeparator';

export default function LogosComponent(props: any) {
  const styles = {
    logosComponentMainContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: { xs: '55px', md: '60px' },
    },
    firstRowLogos: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: { xs: '60px', lg: '155px' },
    },
    secondRowLogos: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: { xs: '60px', md: '75px' },
    },
  };

  return (
    <Box sx={styles.logosComponentMainContainer}>
      <Grid container justifyContent="center" alignItems={'center'} columnSpacing={0} rowSpacing={8.5}>
        {props.logosFirstRow.map(
          (logo: { logoImage: string; logoLink: string; alt: string; sxWidth: string; smWidth: string; sxHeight: string; smHeight: string }) => (
            <Grid key={logo.logoImage} item margin={'0 2.708vw'}>
              <Link href={logo.logoLink} target="_blank" rel="noopener">
                <CardMedia
                  component={'img'}
                  src={logo.logoImage}
                  alt={logo.alt}
                  sx={{ width: { xs: logo.sxWidth, sm: logo.smWidth }, height: { xs: logo.sxHeight, sm: logo.smHeight } }}
                />
              </Link>
            </Grid>
          ),
        )}
      </Grid>
      <Box className="logos-separator-container">
        <IconSeparator iconImage={logos_separator}></IconSeparator>
      </Box>
      <Grid container justifyContent="center" alignItems={'center'} columnSpacing={0} rowSpacing={8.5}>
        {props.logosSecondRow.map((logo: { logoImage: string; logoLink: string; alt: string; width: string; height: string }) => (
          <Grid key={logo.logoImage} item margin={'0 2.708vw'}>
            <CardMedia component={'img'} src={logo.logoImage} alt={logo.alt} width={logo.width} height={logo.height} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
