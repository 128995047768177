import React from 'react';
import { Box, CardMedia, Link, Typography } from '@mui/material';
import { blowfish_white_logo } from '../../assets/images/footer-component';
import colors from '../../utils/colors';
import fonts from '../../utils/fonts';
import { brandLogoLinks } from '../../utils/GeneralUtils';

const footerTextStyles = {
  fontFamily: fonts.openSans,
  fontSize: '14px',
  textAlign: 'center',
  fontWeight: 400,
  color: colors.white,
  textDecoration: 'none',
  marginRight: '20px',
  marginLeft: '20px',
};

const footerLogoStyles = {
  objectFit: 'contain',
  width: '120px',
  height: '36px',
  marginBottom: '22px',
};

const footerContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  paddingBottom: '150px',
  paddingTop: '176px',
  position: 'relative',
  marginTop: 'auto',
};

export default function FooterComponent() {
  return (
    <Box sx={footerContainerStyles}>
      <Link href={brandLogoLinks.blowfish} target="_blank" rel="noopener">
        <CardMedia component={'img'} src={blowfish_white_logo} sx={footerLogoStyles} alt={'Blowfish studios logo in white'} />
      </Link>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} marginBottom={'32px'}>
        <Link href={'/shadowman_privacy_policy.pdf'} sx={footerTextStyles} className="footer-link">
          Privacy Policy
        </Link>
        <Link href={'/shadowman_terms_and_conditions.pdf'} sx={footerTextStyles} className="footer-link">
          Terms of Use
        </Link>
        <Link href={'/shadowman_ad_choice_policy.pdf'} sx={footerTextStyles} className="footer-link">
          Data Collection
        </Link>
      </Box>
      <Typography sx={{ ...footerTextStyles, fontFamily: fonts.lato, mb: '8px' }}>
        SHADOWMAN® and their associated logos are trademarks of Valiant Entertainment LLC.
      </Typography>{' '}
      <Typography sx={{ ...footerTextStyles, fontFamily: fonts.lato }}>
        All rights reserved. Valiant and all its associated characters and logos are registered trademarks of Valiant Entertainment LLC.
      </Typography>
    </Box>
  );
}
