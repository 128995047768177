import React, { useState } from 'react';
import { Box, Link, Typography } from '@mui/material';

export default function SocialButtonWithText(props: any) {
  const [image, setImage] = useState(props.idleImage);
  const [textColor, setTextColor] = useState(props.idleTextColor);

  const handleOnMouseEnter = () => {
    setImage(props.hoveredImage);
    setTextColor(props.hoveredTextColor);
  };

  const handleOnMouseDown = () => {
    setImage(props.pressedImage);
    setTextColor(props.pressedTextColor);
  };

  const handleOnMouseLeave = () => {
    setImage(props.idleImage);
    setTextColor(props.idleTextColor);
  };

  return (
    <Link
      href={props.href}
      target="_blank"
      rel="noopener"
      underline="none"
      onMouseEnter={handleOnMouseEnter}
      onMouseDown={handleOnMouseDown}
      onMouseLeave={handleOnMouseLeave}
      display={'flex'}
      flexDirection={'row'}
      className="social-link"
      title={'social-link'}
    >
      <Box title={'social-icon'} sx={{ ...props.style, backgroundImage: `url(${image})` }} />
      <Typography title={'social-text'} sx={{ ...props.textStyle, color: textColor }}>
        {props.text}
      </Typography>
    </Link>
  );
}
