import React, { useState } from 'react';
import { Box } from '@mui/material';

import './SubscriberForm.css';
import { subscriber_red_warning_sign } from '../../assets/images';

const SubscriberForm = () => {
  const [error, setError] = useState<string>('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const mailerLiteUrl = 'https://assets.mailerlite.com/jsonp/327827/forms/85944319162188952/subscribe';

  const styles = {
    subscribeContainer: {
      transform: 'translateY(-5px)',
    },
    subscribeMessageContainer: {
      display: 'flex',
      marginLeft: '5px',
    },
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubscribed(false);
    const formData = new FormData(event.currentTarget);

    const email = formData.get('fields[email]')?.toString().trim() ?? '';

    const isEmailValid = validateEmail(email);

    // We return early to prevent API call if input field is empty or is not a valid email
    if (!isEmailValid) {
      setError('Please enter a valid email');
      setIsDisabled(false);
      return;
    }

    setIsDisabled(true);

    try {
      const response = await fetch(mailerLiteUrl, {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();

      // Handle the response here
      if (!data.success) {
        setError(data.errors.fields.email);
        setIsDisabled(false);
        return;
      } else {
        setError('');
      }

      setIsSubscribed(true);
      setIsDisabled(false);
    } catch (error) {
      console.error(error);
      // Handle the error here
      setError('An error occurred. Please try again later.');
      setIsDisabled(false);
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i;

    return !(!email || email.length === 0 || !emailRegex.test(email));
  };

  const errorMessage = (error: string) => {
    return error !== '' ? 'Please enter a valid email' : '';
  };

  return (
    <Box sx={styles.subscribeContainer}>
      <form className="ml-block-form" action={mailerLiteUrl} data-code="" method="post" onSubmit={handleSubmit}>
        <div className="ml-form-formContent">
          <div className="ml-form-fieldRow ml-last-item">
            <div className="ml-field-group ml-field-email ml-validate-email ml-validate-required">
              <input
                className={error ? 'form-control form-error' : 'form-control'}
                aria-label="email"
                aria-required={true}
                type="email"
                data-inputmask=""
                name="fields[email]"
                placeholder="Email Address"
                autoComplete="email"
              />
            </div>
          </div>
        </div>
        <input type="hidden" name="ml-submit" value="1" />
        <div className="ml-form-embedSubmit">
          <button type="submit" className="primary" disabled={isDisabled}>
            SIGN UP
          </button>
        </div>
        <input type="hidden" name="anticsrf" value="true" />
      </form>
      <Box sx={styles.subscribeMessageContainer}>
        {error && (
          <img className={'ml-form-error-icon'} src={subscriber_red_warning_sign} alt={'Red triangle warning sign with black exclamation mark'} />
        )}
        <p className={error ? 'ml-form-message ml-form-error' : 'ml-form-message'}>
          {error && errorMessage(error)}
          {isSubscribed && 'Please check your email for confirmation'}
        </p>
      </Box>
    </Box>
  );
};

export default SubscriberForm;
