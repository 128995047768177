import './PlatformComponent.css';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import {
  add_platform_icon,
  add_platform_icon_hovered,
  add_platform_label_hovered,
  platform_label_background,
} from '../../assets/images/platform-component';
import fonts from '../../utils/fonts';
import colors from '../../utils/colors';
import './PlatformComponent.css';

export interface PlatformProps {
  platformImage: string;
  platformLink: string;
  platformName: string;
  platformAltText: string;
}

export default function PlatformComponent(props: PlatformProps) {
  const [buttonImage, setButtonImage] = useState(add_platform_icon);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [platformLabelContainerStyle, setPlatformLabelContainerStyle] = useState({
    backgroundImage: `url(${platform_label_background})`,
    width: '193px',
    height: '39px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.2s ease-in-out',
  });

  const mouseEnterHandler = () => {
    setButtonImage(add_platform_icon_hovered);
    setPlatformLabelContainerStyle({ ...platformLabelContainerStyle, backgroundImage: `url(${add_platform_label_hovered})` });
  };

  const mouseLeaveHandler = () => {
    setButtonImage(add_platform_icon);

    setPlatformLabelContainerStyle({ ...platformLabelContainerStyle, backgroundImage: `url(${platform_label_background})` });
    if (buttonClicked) {
      setButtonClicked(false);
    }
  };

  return (
    <Box display={'flex'} flexDirection={'column'} alignItems={'center'} paddingBottom={'5px'}>
      <Box marginBottom={'38px'}>
        <img src={props.platformImage} alt={props.platformAltText} />
      </Box>
      <Box role={'button'} sx={platformLabelContainerStyle}>
        <a className="wishlist-link" href={props.platformLink} target="_blank">
          <Box
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveHandler}
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: '18px',
              marginTop: '2px',
              width: '100%',
              cursor: 'pointer',
            }}
          >
            <Box position={'relative'} width={'112px'} fontFamily={fonts.poisonHope} fontSize={18} color={colors.black} paddingLeft={'8px'}>
              {props.platformName}
            </Box>
            <Box position={'relative'}>
              <Box
                sx={{
                  backgroundImage: `url(${buttonImage})`,
                  transition: 'all ease-in-out 0.2s',
                  width: '49px',
                  height: '49px',
                }}
              />
            </Box>
          </Box>
        </a>
      </Box>
    </Box>
  );
}
