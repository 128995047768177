import React, { useState } from 'react';
import './HamburgerMenu.css';
import { Box, CardMedia, Link, Typography } from '@mui/material';
import {
  hamburger_close_btn_smoke,
  hamburger_menu_divider,
  mobile_brush_stroke_edge,
  twitter_icon_white,
  twitter_icon_smoke,
  youtube_icon_white,
  youtube_icon_smoke,
  hamburger_close_btn_white,
} from '../../assets/images';
import colors from '../../utils/colors';
import styles from './HamburgerMenuStyle';
import SocialButtonWithText from '../social-button-with-text/SocialButtonWithText';
import SubscriberForm from '../main-menu/SubscriberForm';

export default function HamburgerMenu(props: any) {
  const [titleColor, setTitleColor] = useState(colors.white);
  const [closeButton, setCloseButton] = useState(hamburger_close_btn_white);

  const toggleHamburgerMenu = () => () => {
    props.setHamburgerMenuButton(!props.hamburgerMenuButton);
  };

  return (
    <Box sx={{ display: props.hamburgerMenuButton, overflow: 'hidden' }} className="hamburger-main-menu-container">
      <Box sx={{ height: 1 }}>
        <img src={mobile_brush_stroke_edge} alt="Mobile Brush Stroke Edge" height={'100%'} width={'57px'} />
      </Box>
      <Box
        sx={{
          width: 272,
          backgroundColor: colors.black,
          opacity: 0.9,
          marginLeft: '-29px',
        }}
      >
        <Box className="hamburger-main-menu-body-container">
          <Box>
            <CardMedia
              component="img"
              image={closeButton}
              title="Close Button"
              sx={styles.closeButton}
              onClick={toggleHamburgerMenu()}
              onMouseDown={() => setCloseButton(hamburger_close_btn_smoke)}
              onMouseLeave={() => setCloseButton(hamburger_close_btn_white)}
              alt={'Button in shape of an X for mobile menu'}
            />
          </Box>

          <Box className="hamburger-main-menu-content-container">
            <Link
              href={'#faq'}
              rel="noopener"
              underline={'none'}
              onMouseDown={() => setTitleColor(colors.smoke)}
              onMouseLeave={() => setTitleColor(colors.white)}
              sx={{ ...styles.hamburgerMainMenuText, color: titleColor, width: '142px', textAlign: 'right', marginLeft: '108px' }}
            >
              WELCOME TO THE DEADSIDE
            </Link>
            <Box className="hamburger-main-menu-subscribe">
              <div className="hamburger-menu-divider-container">
                <img src={hamburger_menu_divider} alt="Hamburger Menu Divider" />
              </div>
              <Box display={'flex'} flexDirection={'row'} marginLeft={'-12px'}>
                <SubscriberForm />
              </Box>
              <div className="hamburger-menu-divider-container">
                <img src={hamburger_menu_divider} alt="Hamburger Menu Divider" />
              </div>
            </Box>
            <Typography sx={{ ...styles.followUsText }}>follow us</Typography>
            <Box marginLeft={17.5}>
              <SocialButtonWithText
                style={{ ...styles.socialLogo, margin: '3px 7px 0 0' }}
                idleImage={twitter_icon_white}
                hoveredImage={twitter_icon_white}
                pressedImage={twitter_icon_smoke}
                href={'https://twitter.com/ShadowmanDL'}
                text={'twitter'}
                textStyle={styles.hamburgerMainMenuText}
                idleTextColor={colors.white}
                hoveredTextColor={colors.white}
                pressedTextColor={colors.smoke}
              ></SocialButtonWithText>
            </Box>
            <Box marginTop={3} marginLeft={17}>
              <SocialButtonWithText
                style={{ ...styles.socialLogo, margin: '3px 7px 0 0' }}
                idleImage={youtube_icon_white}
                hoveredImage={youtube_icon_white}
                pressedImage={youtube_icon_smoke}
                href={'https://youtu.be/FOmky34JgZ8'}
                text={'youtube'}
                textStyle={styles.hamburgerMainMenuText}
                idleTextColor={colors.white}
                hoveredTextColor={colors.white}
                pressedTextColor={colors.smoke}
              ></SocialButtonWithText>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
