import React from 'react';
import { Box, CardMedia } from '@mui/material';
import { title_left_decoration, title_right_decoration } from '../../assets/images/title-and-subtitles';

interface TitleAndSubtitleProps {
  image: string;
  alt: string;
}

const titleDecorationStyle = {
  width: '129px',
  height: '96px',
  display: {
    sm: 'block',
    xs: 'none',
  },
};

const titleStyle = {
  width: '315px',
  height: '130px',
  margin: '0 43px',
};

const titleAndSubtitleContainerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1,
};

export default function TitleAndSubtitle(props: TitleAndSubtitleProps) {
  return (
    <Box sx={titleAndSubtitleContainerStyle}>
      <CardMedia sx={titleDecorationStyle} component={'img'} src={title_left_decoration} alt={'Decorative image for left of title'} />
      <CardMedia sx={titleStyle} component={'img'} src={props.image} alt={'Shadowman style themed text written as Coming in Q4 2024'} />
      <CardMedia sx={titleDecorationStyle} component={'img'} src={title_right_decoration} alt={'Decorative image for right of title'} />
    </Box>
  );
}
