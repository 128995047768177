import React from 'react';
import './IconSeparator.css';
import { Box } from '@mui/material';

interface IconImage {
  iconImage: string;
}

export default function IconSeparator(props: IconImage) {
  return (
    <Box className={'icon-separator-container'}>
      <img src={props.iconImage} alt={'Icon Separator'} />
    </Box>
  );
}
