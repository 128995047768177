import { epic_platform, ps_platform, steam_platform, xbox_platform } from '../assets/images/platform-component';
import { bfs_white_logo, pc_digital_logo, ps5_logo, valiant_logo_large, xbox_series_logo } from '../assets/images';

export const brandLogoLinks = {
  blowfish: 'https://www.blowfishstudios.com/',
  valiant: 'https://valiantentertainment.com/',
};

export const platforms = [
  {
    platformImage: steam_platform,
    platformLink: 'https://store.steampowered.com/app/1578870/Shadowman_Darque_Legacy/',
    platformName: 'STEAM',
    platformAltText: 'The Valve Steam logo',
  },
  {
    platformImage: epic_platform,
    platformLink: 'https://store.epicgames.com/en-US/p/shadowman-darque-legacy-98fad9',
    platformName: 'EPIC GAMES',
    platformAltText: 'Epic games logo',
  },
  {
    platformImage: xbox_platform,
    platformLink: 'https://www.xbox.com/en-US/games/store/shadowman-darque-legacy/9mvc30dcbcnz',
    platformName: 'XBOX',
    platformAltText: 'Xbox logo',
  },
  {
    platformImage: ps_platform,
    platformLink: 'https://store.playstation.com/en-au/concept/10005822/',
    platformName: 'PLAYSTATION',
    platformAltText: 'Playstation logo',
  },
];

export const logosFirstRow = [
  {
    logoImage: valiant_logo_large,
    logoLink: brandLogoLinks.valiant,
    alt: 'Valiant Logo',
    sxWidth: '150px',
    smWidth: '200px',
    sxHeight: '40px',
    smHeight: '54px',
  },
  {
    logoImage: bfs_white_logo,
    logoLink: brandLogoLinks.blowfish,
    alt: 'BFS Logo',
    sxWidth: '150px',
    smWidth: '200px',
    sxHeight: '40px',
    smHeight: '60px',
  },
];

export const logosSecondRow = [
  { logoImage: xbox_series_logo, logoLink: '#', alt: 'XBOX Series Logo', width: '192px', height: '24px' },
  { logoImage: ps5_logo, logoLink: '#', alt: 'PS5 Logo', width: '154px', height: '34px' },
  { logoImage: pc_digital_logo, logoLink: '#', alt: 'PC Digital Logo', width: '41px', height: '62px' },
];

export const contentRatingText =
  'Content is generally suitable for ages 16 and up. May contain intense violence, blood and gore, sexual content and /or strong language.';

export const deadsideParagraphs = [
  {
    title: 'What type of game is SHADOWMAN® Darque Legacy?',
    description:
      'SHADOWMAN® Darque Legacy is a third-person action-horror adventure that allows players to take up the scythe of Shadowman, following a dark path from the land of the living to the hellish realm of the Deadside.',
  },
  {
    title: 'Who is developing and publishing SHADOWMAN® Darque Legacy?',
    description:
      'SHADOWMAN® Darque Legacy is developed and published by Blowfish Studios, an award-winning Sydney-based developer and publisher of high-quality multi-platform games, including Winter Ember, Blackwind, Siegecraft, Morphite, Projection: First Light, and Storm Boy.',
  },
  {
    title: 'When will SHADOWMAN® Darque Legacy release?',
    description:
      'The SHADOWMAN® Darque Legacy release date will be revealed at a later time. Subscribe to our mailing list to receive timely updates for the game.',
  },
  {
    title: 'What platforms will SHADOWMAN® Darque Legacy be available on?',
    description: 'SHADOWMAN® Darque Legacy will be available on PlayStation and Xbox consoles, and PC.',
  },
  {
    title: 'How does SHADOWMAN® Darque Legacy fit into the Shadowman universe?',
    description:
      'SHADOWMAN® Darque Legacy will be a standalone game, telling a brand-new, out of continuity story starring Shadowman. This original story has been developed by Blowfish Studios in partnership with Valiant Entertainment',
  },
  {
    title: 'Will SHADOWMAN® Darque Legacy tie in with any other comic or transmedia properties?',
    description:
      'Yes. The 2023 SHADOWMAN® Darque Legacy Free Comic Book Day Special issue explores a prequel story to the game, with a chilling retelling of Shadowman’s origin. We are also exploring many forms of meaningful transmedia collaboration and creations.',
  },
];
